<template>
<div>
  <div class="container-fluid">
    <PageTitle noAdd />
    <template v-if="isList">
      <card-list 
        :filter.sync="filter"
        :dataListing.sync="data"
        :idKey="idKey"
        :fields="fields"

        :no-btn-mass="true"
        :no-multi-select="true"
      > 
        <template #filters>
          <b-col md=3>
            <VSelect 
              v-model="filter.type" 
              placeholder="-- Semua Tipe --"
              :options="mrType" 
              :reduce="v=>String(v.value)"
              @input="doFilter"
            />
          </b-col>
        </template>
        <template #bc_initial="data">
          <p :class="data.scope.item.bc_type === 'EMAIL' ? 'badge badge-info' : 'text-white badge badge-warning'" class="p-1 pr-2 pl-2 text-semibold">
            {{ data.scope.item.bc_type === 'EMAIL' ? 'Email' : 'Notifikasi' }}
          </p>
          <h6 class="text-semibold">
            {{ data.scope.value }}
          </h6>
          <small v-if="data.scope.item.bc_variable" class="d-block">
            variable: {{data.scope.item.bc_variable}}
          </small>
        </template>
        <template #bc_subject="data">
          {{ data.scope.item.bc_type=='EMAIL'?data.scope.item.bc_subject:data.scope.item.bc_content }}
        </template>
        <template #bc_update_date="data">
          {{data.scope.value | moment("MMM DD YYYY, HH:mm ")}}
        </template>
        <template #aksi="data">
          <div v-if="!moduleRole('ViewOnly')" class="btn-toolbar">
            <b-button v-tooltip="'Lihat Detail'" class="mr-2" @click="doView(data.scope.item)">
              <i class="icon-eye"></i>
            </b-button>
            <b-button v-tooltip="'Ubah'" :to="{name:$route.name, params:{pageSlug:data.scope.item[idKey]}}">
              <i class="icon-pencil"></i>
            </b-button>
          </div>
          <span v-else>-</span>
        </template>
      </card-list>

      <b-modal 
        id="ModalDetail" 
        title="Lihat Konten"
        size="lg"
        ok-only
        :hide-footer="true"
      >
        <template v-if="row.bc_id">
          <div class="d-flex justify-content-center">
            <span v-html="row.bc_content"></span>
          </div>
        </template>
      </b-modal>
    </template>
    <div 
      v-else 
      class="card"
    >
      <div class="card-body">
        <h5 class="card-title mb-3">{{isAdd?'Tambah':'Ubah'}} Konten</h5>
        
        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
          <form @submit.prevent="handleSubmit(doSubmitCRUD)">
            <b-col md=3>
              <b-form-group label="Tipe Notifikasi:">
                <b-input 
                  type="text"
                  :value="row.bc_type"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col md=9>
              <b-form-group label="Notifikasi Untuk:">
                <b-input 
                  type="text"
                  :value="row.bc_initial"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col v-if="row.bc_variable" md=9>
              <b-form-group label="Variable:">
                <b-textarea 
                  type="text"
                  :value="row.bc_variable"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col 
              v-if="row.bc_type === 'EMAIL'"
              md=9 
            >
              <b-form-group>
                <template #label>
                  Subjek <span class="text-danger mr5">*</span>
                </template>
                <b-input 
                  type="text"
                  v-model="row.bc_subject"
                />
                <VValidate 
                  name="Subjek" 
                  v-model="row.bc_subject" 
                  rules="required"
                />
              </b-form-group>
            </b-col>
            <b-col md=9>
              <b-form-group>
                <template #label>
                  {{ row.bc_type=='EMAIL'?'Konten':'Notifikasi' }} <span class="text-danger mr5">*</span>
                </template>
                <CKEditor 
                  :value.sync="row.bc_content"
                   v-if="row.bc_type === 'EMAIL'"
                />
                <b-form-textarea
                  v-model="row.bc_content"
                  rows="5"
                  v-else
                >
                </b-form-textarea>
                <VValidate 
                  :name="row.bc_type=='EMAIL'?'Konten':'Notifikasi'"
                  v-model="row.bc_content" 
                  rules="required" 
                />
              </b-form-group>
            </b-col>
            <div class="row mt-4">
              <div class="col-md-2 offset-md-10">
                <button type="submit" class="btn btn-block btn-rounded  btn-info">Submit</button>
              </div>
            </div>
          </form>
        </ValidationObserver>

      </div>
    </div>

  </div>
</div>
</template>

<script>
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'
import CardList from '@/components/CardList'
import CKEditor from '@/components/CKEditor'

export default{
  extends:GlobalVue,
  components:{PageTitle, CardList, CKEditor},
  data(){
    return {
      idKey:'bc_id',
      fields:[
        { key:'number', label:'#' },
        { key:'bc_initial', label:'Notifikasi Untuk', is_custom: true, },
        { key:'bc_subject', label:'Subjek/Notifikasi', is_custom:true},
        { key:'bc_update_date', label: 'Terakhir Update', is_custom: true},
        { key:'aksi', is_custom:true, tdClass:"w_100"},
      ],
      mrType: [
        {label: "Email", value: "EMAIL"},
        {label: "Notifikasi", value: "NOTIFICATION"},
      ]
    }
  },
  mounted(){
    this.apiGet()
  },
  methods: {
    doView(data){
      this.$set(this, 'row', data)
      this.$bvModal.show("ModalDetail")
    }
  },
  watch:{
    $route(){
      this.apiGet()
    }
  }
}
</script>